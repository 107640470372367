import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Blur from "react-blur";
function App() {
  const ref = useRef();
  const [data, setData] = useState([]);
  const [blurRaduis, setBlurRadius] = useState(0);
  const [onit, setOnit] = useState({ boolean: false, id: null });
  function ConvertDate(str) {
    const dateString = str; // ISO8601 compliant dateString
    const D = new Date(dateString);

    return D.getDate() + "." + (D.getMonth() + 1) + "." + D.getFullYear();
  }

  useEffect(() => {
    axios
      .post("https://extal-api.wdev.co.il/api/admin/Menagment/GetCatalogs")
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      });
  }, []);
  return (
    <div className="main">
      <nav>
        <img src="extal-logo.svg" alt="extal-logo" />
      </nav>
      <section>
        <div className="catalog-grid">
          {data ? (
            data.map((item) => {
              return (
                <div
                  onMouseEnter={() => {
                    console.log(item.id);
                    setBlurRadius(5);
                    setOnit({ boolean: true, id: item.id });
                  }}
                  onMouseLeave={() => {
                    setBlurRadius(0);
                    setOnit(false);
                  }}
                  key={item.id}
                  className="item"
                >
                  <a
                    target="_black"
                    href={`https://extal.wdev.co.il/?catalogid=${item.id}`}
                  >
                    <h3
                      style={{
                        color: onit.id === item.id ? "black" : "transparent",
                        width: "100%",
                      }}
                      className="centered"
                    >
                      לצפייה בקטלוג
                    </h3>
                    <img
                      style={{
                        filter:
                          onit.id === item.id
                            ? `blur(${blurRaduis}px)`
                            : "none",
                      }}
                      className="item-image"
                      src={`https://${item.ShowImage}`}
                      alt="item"
                    />
                    <div>
                      <h3>{item.CatalogName} קטלוג</h3>
                      <p style={{ marginBottom: "5px" }}>
                        {ConvertDate(item.Date)}
                      </p>
                    </div>
                  </a>
                </div>
              );
            })
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </section>
      <footer>
        <img src="wave-logo.svg" alt="extal-logo" />
      </footer>
    </div>
  );
}

export default App;
